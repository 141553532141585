import React, { useEffect, useState } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import LineWrappingInput from "react-line-wrapping-input";
import EmojiPicker from "emoji-picker-react";

export default function Chat({
  axios,
  socket,
  username,
  setUsername,
  room,
  setRoom,
  showChat,
  setShowChat,
  noChatbot,
  setNoChatbot,
  setEmpImage,
  empImage,
  feedData,
  currentUser,
  currentUserEmail,
  joinedRoom,
  setJoinedRoom,
  leftRoom,
  setLeftRoom,
  endChat,
  setEndChat,
  startChat,
  setStartChat,
  startOldChat,
  setStartOldChat,
  isAdmin,
  chatHistory,
  chatDepartments,
  env,
  setNewMessage,
  startTime,
  endTime,
  onlineStatus,
  buttons,
  translation,
}) {
  const [currentMessage, setCurrentMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [myOwnInput, setInputValue] = useState([]);
  const [formData, setFormData] = useState([]);
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState("");
  const [newChat, setNewChat] = useState(false);
  const [oldChat, setOldChat] = useState(false);
  const [oldChatCode, setOldChatCode] = useState(false);
  const [chatMenu, setChatMenu] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [chatWindowForm, setChatWindowForm] = useState(false);
  const [ratingForm, setRatingForm] = useState(false);
  const [ticketForm, setTicketForm] = useState(false);
  const [attachmentForm, setAttachmentForm] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [allRooms, setAllRooms] = useState(false);
  const [allRoomsActive, setAllRoomsActive] = useState([]);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showButtons, setShowButons] = useState(false);

  /* const currentYear = new Date(Date.now()).getFullYear(); */

  /* function for displaying loading icon */
  const LoadingIcon = (e) => {
    if (e.target.value && !leftRoom) {
      const inputData = {
        inputVal: e.target.value,
        author: currentUser,
        room: room,
      };
      socket.emit("send_typing", inputData);
    } else {
      const inputData = {
        room: room,
      };
      socket.emit("send_typing", inputData);
    }
  };

  /* handle reaction */
  const handleReaction = (emoji) => {
    insertEmoji(emoji.emoji);
  };

  /* insert emoji into text */
  const insertEmoji = (emoji) => {
    const input = document.querySelector(".line-wrapping-input ");
    const cursorPos = input.selectionStart;
    const textBeforeCursor = input.value.substring(0, cursorPos);
    const textAfterCursor = input.value.substring(cursorPos);
    input.value = textBeforeCursor + emoji + textAfterCursor;
    input.selectionStart = input.selectionEnd = cursorPos + emoji.length;
    input.focus();
    setCurrentMessage(input.value);
    setShowEmojis(false);
  };

  /* Chat control function for starting a new chat or reloading an old one */

  const chatControl = (e) => {
    if (e.target.dataset.name === "oldChat") {
      setOldChat(true);
      setNewChat(false);
      setOldChatCode(false);
    } else if (e.target.dataset.name === "cancelCode") {
      setOldChat(false);
      setNewChat(false);
      setOldChatCode(false);
      setStartOldChat(false);
    } else if (e.target.dataset.name === "newChat") {
      setOldChat(false);
      setNewChat(true);
      setOldChatCode(false);
    }
  };

  /* fuunction for fetch all assigned chatrooms */

  const getActiveRooms = () => {
    axios
      .get("" + env.URL_LARAVEL + "/api/v1/activeRooms/" + room)
      .then((response) => {
        if (response.data.toString().includes("Error")) {
          console.log(response.data);
        } else {
          setAllRoomsActive(response.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  /* set chatMenu */

  const chatMenuFunction = (e) => {
    const tData = e.target.dataset.action;
    if (tData === "openCloseMenu") {
      if (chatMenu) setChatMenu(false);
      else setChatMenu(true);
    } else if (tData === "allRooms") {
      getActiveRooms();
      setAllRooms(true);
      setChatWindowForm(true);
    } else if (tData === "emailCustomer") {
      setChatWindowForm(true);
      setSendEmail(true);
    } else if (tData === "createTicket") {
      setChatWindowForm(true);
      setTicketForm(true);
    } else if (tData === "endChat" || tData === "leaveChat") {
      setShowChat(false);
      setNewMessage(false);

      let roomData = "";

      if (tData === "leaveChat") {
        setLeftRoom(true);
        setEndChat(false);
        window.top.postMessage("leaveChat|" + room, "*");
        roomData = {
          room: room,
          author: currentUser,
          tdata: "leaveChat",
        };
        socket.emit("left_room", roomData);
      }

      if (tData === "endChat") {
        setLeftRoom(false);
        setEndChat(true);
        localStorage.clear();
        window.top.postMessage(
          "endChat|" + room + "|" + localStorage.getItem("showWelcome"),
          "*"
        );
        roomData = {
          room: room,
          author: currentUser,
          tdata: "endChat",
        };
        socket.emit("end_chat", roomData);
      }

      setStartChat(false);
      setNoChatbot(false);
      setJoinedRoom(false);
      setOldChat(false);
      setStartOldChat("");
      setNewChat(false);
      setOldChatCode(false);
      setMessageList([]);
      setEmpImage("chatbackground.jpg");
      setRoom("");
      window.localStorage.clear();
    } else if (tData === "sendChatHistory") {
      setWaiting(true);
      socket.emit("chat_history", { room: room, lang: translation.language });
    } else if (tData === "rateChat") {
      setChatWindowForm(true);
      setRatingForm(true);
    } else if (tData === "sendAttachment") {
      setChatWindowForm(true);
      setAttachmentForm(true);
    }
  };

  /* remove any active popup when the chat window is clicked */

  const chatWindowClicked = () => {
    if (chatMenu) setChatMenu(false);
  };

  /* set new chat state funciton */
  const setNewState = () => {
    setShowChat(false);
    setNewMessage(false);
    window.top.postMessage(
      "closeChat|" + localStorage.getItem("showWelcome"),
      "*"
    );
  };

  /* set new chat state funciton */
  const closeChatWindowForm = () => {
    setChatWindowForm(false);
    setRatingForm(false);
    setSendEmail(false);
    setTicketForm(false);
    setAllRooms(false);
    setAttachmentForm(false);
  };

  /* function to send open room as a post message */

  const sendOpenRoom = (e) => {
    const btn_room = e.target.dataset.room;
    window.top.postMessage("open_" + btn_room, "*");
    setAllRooms(false);
    setChatWindowForm(false);
  };

  /* function for sending forms */
  const sendForm = async (e) => {
    e.preventDefault();
    setWaiting(true);
    setFormError("");
    const form_name = e.target.dataset.form;
    const formEl = new FormData(document.forms[form_name]);
    let formData = [];

    for (const pair of formEl.entries()) {
      let errorMsg = "";
      let successMsg = "";
      if (pair[0] === "Upload" && pair[1].name) {
        const fileExtensions = ["jpg", "jpeg", "png", "pdf"];
        if (fileExtensions.indexOf(pair[1].name.split(".")[1]) !== -1) {
          if (pair[1].size / (1024 * 1024) < 5) {
            await axios
              .post("" + env.URL_HOST + "/sendEmail", formEl)
              .then((response) => {
                if (response.data.toString().includes("Error")) {
                  errorMsg = response.data.split(":")[1];
                } else {
                  successMsg = response.data;
                }

                if (errorMsg) {
                  setWaiting(false);
                  setFormError(errorMsg);
                  return setTimeout(() => {
                    setFormError("");
                  }, 3000);
                } else if (successMsg) {
                  formData.push(["upload", successMsg]);
                }
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          } else {
            setWaiting(false);
            setFormError(translation.fileSize);
            return setTimeout(() => {
              setFormError("");
            }, 3000);
          }
        } else {
          setWaiting(false);
          setFormError(translation.fileType);
          return setTimeout(() => {
            setFormError("");
          }, 3000);
        }
      } else {
        formData.push([pair[0], pair[1]]);
      }
    }
    formData.push(["translation", translation]);
    socket.emit("send_form", formData);
  };

  /* send buttons */
  const sendButtons = (e) => {
    if (e.target.dataset.message) {
      sendMessage(e.target.dataset.message);
    }
  };

  /* reject function */
  const cancelFunction = (e) => {
    e.preventDefault();
    setFormData([]);
    const data = {
      room: room,
      translation: translation,
    };
    socket.emit("rejected_function", data);
  };

  /* function for sending messages into the chat window */
  const sendMessage = async (message = null) => {
    if (startChat && !leftRoom && !endChat) {
      if (currentMessage !== "" || message) {
        setShowButons(false);
        if (joinedRoom) setJoinedRoom(false);

        const adminImage = document.querySelector(".the_submit").dataset.img;
        setFormData([]);
        const messageData = {
          room: room,
          author: currentUser,
          message: currentMessage !== "" ? currentMessage : message,
          time: new Date(Date.now()).toLocaleString("de-DE", {
            dateStyle: "medium",
            timeStyle: "short",
            hour12: false,
          }),
          nochatbot:
            window.localStorage.getItem("no_chatbot") &&
            window.localStorage.getItem("no_chatbot") === "true"
              ? true
              : noChatbot,
          sys_data: feedData,
          history: messageList,
          sys_name: username,
          company_open: checkOpenHours(),
          departments: chatDepartments,
          image: isAdmin ? adminImage : "",
          translation: translation,
        };

        await socket.emit("send_message", messageData);

        const messageDataCut = {
          room: room,
          author: currentUser,
          message: currentMessage !== "" ? currentMessage : message,
          time: new Date(Date.now()).toLocaleString("de-DE", {
            dateStyle: "medium",
            timeStyle: "short",
            hour12: false,
          }),
          image: isAdmin ? adminImage : "",
        };
        setMessageList((list) => [...list, messageDataCut]);
        setCurrentMessage("");
        const inputData = {
          room: room,
        };
        socket.emit("send_typing", inputData);
      }
    }
  };

  /* save message */
  const saveFunction = () => {
    //console.log(room);
    setTimeout(() => {
      if (messageList.length > 0) {
        const msgData = {
          messages: JSON.stringify(messageList),
          room: room,
        };
        socket.emit("save_message", msgData);
      }
    }, 2000);
  };

  messageList && saveFunction();

  /* check if Data is JSON */
  function isJSONObject(variable) {
    try {
      JSON.parse(variable);
      return true;
    } catch (error) {
      return false;
    }
  }

  function checkOpenHours() {
    const startTiming = startTime;
    const endTiming = endTime;

    const currentDate = new Date();

    const startDate = new Date(currentDate.getTime());
    startDate.setHours(startTiming.split(":")[0]);
    startDate.setMinutes(startTiming.split(":")[1]);
    startDate.setSeconds(startTiming.split(":")[2]);

    const endDate = new Date(currentDate.getTime());
    endDate.setHours(endTiming.split(":")[0]);
    endDate.setMinutes(endTiming.split(":")[1]);
    endDate.setSeconds(endTiming.split(":")[2]);

    return (
      onlineStatus === 1 && startDate < currentDate && endDate > currentDate
    );
  }

  /* function for saving the chat data into the database */

  /* all use effect functions */
  useEffect(() => {
    /* when a message is received into the room */
    socket.on("receive_message", (data) => {
      setNewMessage(true);
      setFormData([]);
      setWaiting(false);
      setMessageList((list) => [...list, data]);
      setJoinedRoom(false);
    });

    socket.on("msg_received", (data) => {
      window.top.postMessage(
        "newMessage|" + data.author + "|" + data.room,
        "*"
      );
    });

    /* set show buttons */
    socket.on("buttons", () => {
      setShowButons(true);
    });

    /* recieve saved chat messages */
    socket.on("messageList", (data) => {
      if (isJSONObject(data)) {
        setMessageList(JSON.parse(data));
      } else {
        setMessageList(data);
      }
    });

    /* activate or deaktivate the typing gif*/
    socket.on("receive_typing", (data) => {
      setInputValue(data);
    });

    /* show or hide a form and its input fields*/
    socket.on("form_data", (data) => {
      setWaiting(false);
      setFormData([data]);
    });

    /* show or hide form errors */
    socket.on("error_form", (data) => {
      setWaiting(false);
      setFormError(data);
      setTimeout(() => {
        setFormError("");
      }, 3000);
    });

    /* show or hide form errors */
    socket.on("success_form", (data) => {
      setWaiting(false);
      if (document.getElementById("rateChat"))
        document.getElementById("rateChat").reset();
      if (document.getElementById("createTicket"))
        document.getElementById("createTicket").reset();
      if (document.getElementById("sendAttachment"))
        document.getElementById("sendAttachment").reset();
      setFormSuccess(data);
      setTimeout(() => {
        setFormSuccess("");
      }, 3000);
    });

    /* show or hide form errors */
    socket.on("start_old_chat", (data) => {
      setWaiting(false);
      setStartOldChat(data);
      setFormSuccess(data);
      setTimeout(() => {
        setFormSuccess("");
      }, 3000);
    });

    /* empty the form array */
    socket.on("form_sent", (data) => {
      setWaiting(false);
      setFormData([]);
    });

    /* deactivate AI response */
    socket.on("no_chatbot", (data) => {
      setNoChatbot(data);
      window.localStorage.setItem("no_chatbot", "" + data + "");
    });

    /* change profile picture when customer agent connects */
    socket.on("profile_pic", (data) => {
      setEmpImage(data.image);
      setUsername(data.username);
      setNoChatbot(true);

      window.localStorage.setItem(
        "room",
        JSON.stringify({
          id: data.room,
          bot: true,
          anrede: data.sys_name.split(" ")[0],
          nachname: data.sys_name.split(" ")[1],
          email: currentUserEmail,
          username: data.username,
          alteChat: true,
          session: true,
          empImage: data.image,
        })
      );
      if (!joinedRoom && data.action === "newRoom") {
        setJoinedRoom(true);
        setLeftRoom(false);
      }

      window.localStorage.setItem("online", "true");
      window.localStorage.setItem("room_status", 2);
    });

    /* left room */
    socket.on("leftRoom", () => {
      setLeftRoom(true);
      window.localStorage.setItem("online", "false");
      setEndChat(false);
      setJoinedRoom(false);
    });

    /* send leave chat message to backend */
    socket.on("leftRoomIO", (data) => {
      window.top.postMessage("leaveChat|" + data.room, "*");
    });

    /* end Chat */
    socket.on("endChat", () => {
      setLeftRoom(false);
      setEndChat(true);
      localStorage.clear();
      setJoinedRoom(false);
    });

    /* send leave chat message to backend */
    socket.on("endChatIO", (data) => {
      window.top.postMessage(
        "endChat|" + data.room + "|" + localStorage.getItem("showWelcome"),
        "*"
      );
      socket.emit("leaveAll", data.room);
    });

    socket.on("dash_refresh", () => {
      if (room) getActiveRooms();
      window.top.postMessage("refresh", "*");
    });

    return () => {
      socket.removeListener("dash_refresh");
      socket.removeListener("error_form");
      socket.removeListener("success_form");
      socket.removeListener("receive_message");
      socket.removeListener("messageList");
      socket.removeListener("leftRoom");
      socket.removeListener("leftRoomIO");
      socket.removeListener("endChat");
      socket.removeListener("endChatIO");
    };
  }, []);

  return (
    <div
      className={"chat-window" + (showChat ? " slideUp" : " slideDown")}
      onClick={chatWindowClicked}
    >
      {waiting && (
        <div className="loading-icon-back">
          <img
            src="typing-2.gif"
            style={{ width: "80px", color: "black" }}
            alt="loading gif"
          />
        </div>
      )}
      {chatWindowForm && (
        <div className="start_window">
          <div className="text-right" onClick={closeChatWindowForm}>
            <i className="fa-sharp fa-solid fa-circle-xmark md_text chatClose text-white"></i>
          </div>
          {/* chat rating form */}
          {ratingForm && (
            <form
              encType="multipart/form-data"
              name="rateChat"
              method="POST"
              id="rateChat"
              className="chatWindowForm form-box"
            >
              {formError && <p className="formError mb-4">{formError}</p>}
              {formSuccess && (
                <p className="formError formSuccess mb-4">{formSuccess}</p>
              )}
              <h2 className="nm_text bold_weight color_1 mb-3">
                {translation.rating}
              </h2>
              <input type="hidden" name="room" value={room} />
              <input type="hidden" name="form_name" value="rateChat" />
              <div>
                <p className="tColor1 sm_text mb-2">{translation.zufrieden}</p>
                <input type="radio" id="rate_1" name="rating" value="1" />
                &nbsp;
                <label for="rate_1" className="tColor1 sm_text">
                  1
                </label>
                &nbsp;&nbsp;
                <input type="radio" id="rate_2" name="rating" value="2" />
                &nbsp;
                <label for="rate_2" className="tColor1 sm_text">
                  2
                </label>
                &nbsp;&nbsp;
                <input type="radio" id="rate_3" name="rating" value="3" />
                &nbsp;
                <label for="rate_3" className="tColor1 sm_text">
                  3
                </label>
                &nbsp;&nbsp;
                <input type="radio" id="rate_4" name="rating" value="4" />
                &nbsp;
                <label for="rate_4" className="tColor1 sm_text">
                  4
                </label>
                &nbsp;&nbsp;
                <input type="radio" id="rate_5" name="rating" value="5" />
                &nbsp;
                <label for="rate_5" className="tColor1 sm_text">
                  5
                </label>
                &nbsp;&nbsp;
              </div>
              <label for="rating_message" className="tColor1 sm_text mt-4">
                {translation.message}
              </label>
              <textarea
                name="rating_message"
                id="rating_message"
                cols="30"
                rows="5"
                className="mt-2 form-control w-100"
              ></textarea>
              <div className="mt-4">
                <button
                  className="my_btn my_btn_1 w-100 mb-2 sm_text"
                  onClick={sendForm}
                  data-form="rateChat"
                >
                  {translation.send}
                </button>
              </div>
            </form>
          )}
          {/* ticket form */}
          {ticketForm && (
            <form
              encType="multipart/form-data"
              name="createTicket"
              method="POST"
              id="createTicket"
              className="chatWindowForm form-box"
            >
              {formError && <p className="formError mb-4">{formError}</p>}
              {formSuccess && (
                <p className="formError formSuccess mb-4">{formSuccess}</p>
              )}
              <h2 className="nm_text bold_weight color_1 mb-3">
                {translation.ticket}
              </h2>
              <input type="hidden" name="room" value={room} />
              <input type="hidden" name="form_name" value="createTicket" />
              <label for="title" className="tColor1 sm_text">
                {translation.title}
              </label>
              <input
                type="text"
                name="title"
                id="title"
                className="form-control w-100 mt-2"
              />
              <label for="ticket_message" className="tColor1 sm_text mt-4">
                {translation.description}
              </label>
              <textarea
                name="description"
                id="ticket_message"
                cols="30"
                rows="5"
                className="mt-2 form-control w-100"
              ></textarea>
              <input
                type="file"
                id="Upload"
                className="form-controls mt-4"
                name="Upload"
              />
              <div className="mt-4">
                <button
                  className="my_btn my_btn_1 w-100 mb-2 sm_text"
                  onClick={sendForm}
                  data-form="createTicket"
                >
                  {translation.send}
                </button>
              </div>
            </form>
          )}

          {/* send email to customer */}
          {/* ticket form */}
          {sendEmail && (
            <form
              encType="multipart/form-data"
              name="email_send"
              method="POST"
              id="createTicket"
              className="chatWindowForm form-box"
            >
              {formError && <p className="formError mb-4">{formError}</p>}
              {formSuccess && (
                <p className="formError formSuccess mb-4">{formSuccess}</p>
              )}
              <h2 className="nm_text bold_weight color_1 mb-3">
                {translation.emailTo} {username} {translation.afterSend}
              </h2>
              <input type="hidden" name="room" value={room} />
              <input type="hidden" name="form_name" value="email_send" />
              <input type="hidden" name="form_name_2" value="to_customer" />
              <input type="hidden" name="customer_name" value={username} />
              <label for="title" className="tColor1 sm_text">
                {translation.title}
              </label>
              <input
                type="text"
                name="Betreff"
                id="title"
                className="form-control w-100 mt-2"
              />
              <label for="ticket_message" className="tColor1 sm_text mt-4">
                {translation.message}
              </label>
              <textarea
                name="Nachricht"
                id="ticket_message"
                cols="30"
                rows="5"
                className="mt-2 form-control w-100"
              ></textarea>
              <input
                type="file"
                name="Upload"
                id="Upload"
                className="form-controls mt-4"
              />
              <input
                className="d-none"
                type="checkbox"
                value="Akzeptiert"
                name="Datenschutz"
                checked
              />
              <div className="mt-4">
                <button
                  className="my_btn my_btn_1 w-100 mb-2 sm_text"
                  onClick={sendForm}
                  data-form="email_send"
                >
                  {translation.send}
                </button>
              </div>
            </form>
          )}
          {/* send attachment */}
          {attachmentForm && (
            <form
              encType="multipart/form-data"
              name="sendAttachment"
              method="POST"
              id="sendAttachment"
              className="chatWindowForm form-box"
            >
              {formError && <p className="formError mb-4">{formError}</p>}
              {formSuccess && (
                <p className="formError formSuccess mb-4">{formSuccess}</p>
              )}
              <h2 className="nm_text bold_weight color_1 mb-3">
                {translation.sendAttachment}
              </h2>
              <input type="hidden" name="room" value={room} />
              <input type="hidden" name="form_name" value="sendAttachment" />
              <input type="hidden" name="customer_name" value={currentUser} />
              <label for="title" className="tColor1 sm_text">
                {translation.title}
              </label>
              <input
                type="text"
                name="title"
                id="title"
                className="form-control w-100 mt-2"
              />
              <input
                type="file"
                id="Upload"
                className="form-controls mt-4"
                name="Upload"
              />
              <div className="mt-4">
                <button
                  className="my_btn my_btn_1 w-100 mb-2 sm_text"
                  onClick={sendForm}
                  data-form="sendAttachment"
                >
                  {translation.send}
                </button>
              </div>
            </form>
          )}
          {/* show all assigned rooms */}
          {allRooms && (
            <div className="chatWindowForm py-0">
              {allRoomsActive.length < 1 && (
                <p className="tColor1 bold_weight sm_text py-4">
                  {translation.noAssignedRooms}
                </p>
              )}
              {allRoomsActive.length > 0 && (
                <div className="all-rooms-container">
                  <div className="row mainTcolor bold_weight sm_text pt-3">
                    <div className="col-6">
                      <p>{translation.name}</p>
                    </div>
                    <div className="col-6">
                      <p>{translation.action}</p>
                    </div>
                    <div className="col-12">
                      <hr className="mb-0" />
                    </div>
                  </div>
                  {allRoomsActive.map((roomContent) => {
                    const sub_button_data =
                      document.querySelector(".the_submit");
                    return (
                      <div
                        key={roomContent.room_id}
                        className="row py-3"
                        style={{ borderBottom: "solid 1px rgba(0,0,0,.24)" }}
                      >
                        <div className="col-6 tColor1 sm_text my-auto">
                          {roomContent.user_title} {roomContent.user_lname}
                        </div>
                        <div className="col-6">
                          <button
                            data-room={roomContent.room_id}
                            className="my_btn my_btn_1 join_button d-inline-block btn_sm_text px-4"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={sendOpenRoom}
                          >
                            {translation.open}
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {!startChat && !isAdmin && (
        <div className="start_window">
          <div className="text-right" onClick={setNewState}>
            <i className="fa-solid fa-circle-chevron-down md_text chatClose text-white"></i>
          </div>
          <div className="mt-4">
            <h2 className="md_text bold_weight">{translation.welcomeTitle}</h2>
            <p>{translation.welcomeText1}</p>

            <div className="chat_options mt-5 p-4">
              {!newChat && !oldChat && !oldChatCode && (
                <div>
                  <h3 className="sm_text bolder_weight color_1 mb-3">
                    {translation.welcomeText2}
                  </h3>
                  <p className="xs_text tColor1">
                    {translation.welcomeText3}
                    <br></br>
                    <br></br>
                    {translation.welcomeText4}
                  </p>
                  <hr className="grey_line my-4" />
                  <p className="text-white"></p>
                  <button
                    className="my_btn my_btn_1 w-100 mb-3"
                    data-name="newChat"
                    onClick={chatControl}
                  >
                    {translation.newSession}
                  </button>
                  <button
                    className="my_btn my_btn_2 w-100"
                    data-name="oldChat"
                    onClick={chatControl}
                  >
                    {translation.oldSession}
                  </button>
                </div>
              )}
              {/* OLD CHAT SESSION */}
              {oldChat && !newChat && !startOldChat && (
                <div>
                  <h3 className="sm_text bolder_weight color_1 mb-3">
                    {translation.reqConfirmCode}
                  </h3>
                  {formError && <p className="formError mb-4">{formError}</p>}
                  <form
                    encType="multipart/form-data"
                    name="oldChat"
                    method="POST"
                  >
                    <input type="hidden" name="room" value="noRoom" />
                    <input type="hidden" name="form_name" value="oldChat" />
                    <input
                      type="text"
                      name="email"
                      placeholder={translation.email}
                      className="form-control"
                    />
                    <div className="mt-4">
                      <button
                        className="my_btn my_btn_1 w-100 mb-2 sm_text"
                        onClick={sendForm}
                        data-form="oldChat"
                      >
                        {translation.send}
                      </button>
                      <button
                        className="my_btn my_btn_2 w-100 sm_text"
                        data-name="cancelCode"
                        onClick={chatControl}
                      >
                        {translation.cancel}
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {/* OLD CHAT SESSION after code is received*/}
              {oldChat && !newChat && startOldChat && (
                <div>
                  <h3 className="sm_text bolder_weight color_1 mb-3">
                    {translation.giveConfirmCode}
                  </h3>
                  {formError && <p className="formError mb-4">{formError}</p>}
                  {formSuccess && (
                    <p className="formError formSuccess mb-4">{formSuccess}</p>
                  )}
                  <form
                    encType="multipart/form-data"
                    name="startOldChat"
                    method="POST"
                  >
                    <input type="hidden" name="room" value="noRoom" />
                    <input
                      type="hidden"
                      name="form_name"
                      value="startOldChat"
                    />
                    <input
                      type="text"
                      name="code"
                      placeholder={translation.confirmCode}
                      className="form-control"
                    />
                    <div className="mt-4">
                      <button
                        className="my_btn my_btn_1 w-100 mb-2 sm_text"
                        onClick={sendForm}
                        data-form="startOldChat"
                      >
                        {translation.send}
                      </button>
                      <button
                        className="my_btn my_btn_2 w-100 sm_text"
                        data-name="cancelCode"
                        onClick={chatControl}
                      >
                        {translation.cancel}
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {/* NEW CHAT SESSION */}
              {newChat && !oldChat && (
                <div>
                  <h3 className="sm_text bolder_weight color_1 mb-3">
                    {translation.personalInfo}
                  </h3>
                  {formError && <p className="formError mb-4">{formError}</p>}
                  <form
                    encType="multipart/form-data"
                    name="newChat"
                    method="POST"
                  >
                    <input type="hidden" name="room" value="noRoom" />
                    <input type="hidden" name="form_name" value="newChat" />
                    <select name="anrede" id="anrede" className="form-control">
                      <option value="" selected disabled>
                        {translation.chooseGender} *
                      </option>
                      <option value={translation.mr}>{translation.mr}</option>
                      <option value={translation.mrs}>{translation.mrs}</option>
                    </select>
                    <input
                      type="text"
                      name="nachname"
                      placeholder={translation.lastName + " *"}
                      className="form-control my-3"
                    />
                    <input
                      type="text"
                      name="email"
                      placeholder={translation.email + " *"}
                      className="form-control"
                    />
                    <div className="mt-4">
                      <button
                        className="my_btn my_btn_1 w-100 mb-2 sm_text"
                        data-form="newChat"
                        onClick={sendForm}
                      >
                        {translation.startChat}
                      </button>
                      <button
                        className="my_btn my_btn_2 w-100 sm_text"
                        data-name="cancelCode"
                        onClick={chatControl}
                      >
                        {translation.cancel}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="chat-header">
        <div className="d-flex">
          <div
            className={
              !leftRoom && !endChat
                ? "ChatIconContainerHeader"
                : "ChatIconContainerHeader offlineIcon"
            }
            style={{ backgroundImage: "url(" + empImage + " )" }}
          ></div>
          <div>
            <p>{username ? username : "Support-Bot"}</p>

            <p className="online">
              {!leftRoom && !endChat ? translation.online : translation.offline}
            </p>
          </div>
        </div>
        <div className="my-auto">
          <i
            className="chatClose fa-solid fa-circle-chevron-down color_1 md_text"
            onClick={setNewState}
          ></i>
          <br />
          <i
            className="fa-solid fa-circle-ellipsis color_1 md_text chat_menu_icon"
            onClick={chatMenuFunction}
            data-action="openCloseMenu"
          ></i>
        </div>
      </div>
      {chatMenu && (
        <div className="chat_menu">
          {isAdmin && (
            <ul>
              <li onClick={chatMenuFunction} data-action="allRooms">
                {translation.allAssignedRooms}
              </li>
              <li onClick={chatMenuFunction} data-action="emailCustomer">
                {translation.emailTo} {username} {translation.afterSend}
              </li>
              <li onClick={chatMenuFunction} data-action="createTicket">
                {translation.ticket}
              </li>
              {!chatHistory && (
                <li onClick={chatMenuFunction} data-action="leaveChat">
                  {translation.leaveRoom}
                </li>
              )}
              {!chatHistory && (
                <li
                  id="end_chat"
                  onClick={chatMenuFunction}
                  data-action="endChat"
                >
                  {translation.stopChat}
                </li>
              )}
            </ul>
          )}
          {!isAdmin && (
            <ul>
              <li onClick={chatMenuFunction} data-action="sendChatHistory">
                {translation.sendHistory}
              </li>
              <li onClick={chatMenuFunction} data-action="rateChat">
                {translation.rateChat}
              </li>
              <li onClick={chatMenuFunction} data-action="endChat">
                {translation.stopChat}
              </li>
            </ul>
          )}
        </div>
      )}
      <div className="chat-body">
        <ScrollToBottom className="message-container">
          {messageList.map((messageContent) => {
            let i = Math.random();
            return (
              <div
                key={messageContent.author + "-" + i}
                className="message"
                id={
                  currentUser === messageContent.author ||
                  messageContent.author === "Sie"
                    ? "you"
                    : "other"
                }
              >
                {currentUser !== messageContent.author &&
                  messageContent.author !== "Sie" &&
                  messageContent.image && (
                    <div
                      className="ChatIconContainerMessage"
                      style={{
                        backgroundImage: "url(" + messageContent.image + " )",
                      }}
                    ></div>
                  )}
                <div>
                  <div className="message-meta">
                    <p id="author" className="tColor2 mb-1 xs_text">
                      {messageContent.author
                        ? messageContent.author
                        : "Support-Bot"}
                    </p>
                  </div>
                  <div className="message-content">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: messageContent.message,
                      }}
                    ></p>
                  </div>
                  <div
                    className={
                      currentUser !== messageContent.author
                        ? "text-left pad_time_left"
                        : "text-right pad_time_right"
                    }
                  >
                    <p className="xs_text tColor2 mt-2">
                      {messageContent.time}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
          {showButtons && buttons.length > 0 && (
            <div className="px-5 text-center pb-4">
              {buttons.map((butt) => {
                return (
                  <button
                    className="my_btn my_btn_3"
                    data-message={butt.long_text}
                    onClick={sendButtons}
                  >
                    {butt.short_text}
                  </button>
                );
              })}
            </div>
          )}
          {formData.map((formInput) => {
            let all_fields = "";
            for (const [key, value] of Object.entries(formInput.input_fields)) {
              if (
                typeof value === "object" &&
                !Array.isArray(value) &&
                value !== null
              ) {
                if (value["name"] === "select") {
                  let select_options = "";
                  for (const [key2, value2] of Object.entries(value)) {
                    if (key2 !== "name" && key2 !== "description")
                      select_options += `<option value="${value2}">${key2}</option>`;
                  }
                  all_fields += `<select class="form-control mb-4" name="${key}">
                  <option value="" selected disabled>${value["description"]} *</option>
                  ${select_options}
                  </select>`;
                } else if (value["name"] === "radio") {
                  let radio_options = "";
                  for (const [key2, value2] of Object.entries(value)) {
                    if (key2 !== "name" && key2 !== "description")
                      radio_options += `<div key=${key2}><input type="radio" id="${key2}" name="${key}" value="${value2}">&nbsp;<label for="${key2}">${key2}</label></div>`;
                  }
                  all_fields += `<div class="radioContainer w-100 mb-3"><p class="bold_weight">${value["description"]} *</p><div class="d-flex mt-2">${radio_options}</div></div>`;
                }
              } else {
                if (value.toString().split("|")[0] === "text")
                  all_fields += `<input class="form-control" type="text" name="${key}" placeholder="${
                    value.toString().split("|")[1]
                  } *"><br>`;
                else if (value.toString().split("|")[0] === "file")
                  all_fields += `<input id="${key}" class="form-controls" type="file" name="${key}"><br>`;
                else if (value.toString().split("|")[0] === "textarea")
                  all_fields += `<textarea class="form-control" name="${key}" rows="4" cols="50" placeholder="${
                    value.toString().split("|")[1]
                  } *"></textarea><br>`;
                else if (value.toString().split("|")[0] === "checkbox")
                  all_fields += `<div class="mt-3"><input type="checkbox" id="${key}" name="${key}" value="${translation.accepted}">&nbsp;<label for="${key}">${translation.please} <a href="https://ejcon.de/datenschutz">${translation.privacy}</a> ${translation.accept}. *</label></div>`;
              }
            }

            return (
              <div className="approval-box">
                <p className="mb-2 p-4 text-white bord_rad background_color_1">
                  {formInput.message}
                </p>
                {formError && <p className="formError m-4">{formError}</p>}
                <form
                  className="p-4"
                  encType="multipart/form-data"
                  name={formInput.name}
                  id={formInput.name}
                  method="POST"
                >
                  <input type="hidden" name="room" value={room} />
                  <input
                    type="hidden"
                    name="form_name"
                    value={formInput.name}
                  />
                  <input type="hidden" name="form_name_2" value="to_admin" />
                  <input
                    type="hidden"
                    name="customer_name"
                    value={currentUser}
                  />
                  <div dangerouslySetInnerHTML={{ __html: all_fields }}></div>
                  <div className="d-flex mt-4">
                    <button
                      className="my_btn my_btn_1"
                      data-form={formInput.name}
                      onClick={sendForm}
                    >
                      {translation.send}
                    </button>
                    <button
                      className="my_btn my_btn_2"
                      onClick={cancelFunction}
                    >
                      {translation.cancel}
                    </button>
                  </div>
                </form>
              </div>
            );
          })}
          {/* user has joined room */}
          {joinedRoom && empImage !== "chatbackground.jpg" && (
            <div className="text-center">
              <p className="tColor1 xs_text p-4 tColor2">
                {username + " " + translation.joinedRoom + "."}
              </p>
            </div>
          )}
          {/* user has left room */}
          {leftRoom && !isAdmin && (
            <div className="text-center">
              <p className="tColor1 xs_text p-4 tColor2">
                {username + " " + translation.leftRoom + "."}
              </p>
            </div>
          )}
          {/* user has ended the chat */}
          {endChat && (
            <div className="text-center">
              <p className="tColor1 xs_text p-4 tColor2">
                {translation.chatEnded}.
              </p>
            </div>
          )}
          {/*  show typing gif icon */}
          {myOwnInput && myOwnInput.author && myOwnInput.room === room && (
            <div
              className={
                "w-100 p-1 " +
                (currentUser === myOwnInput.author ? "dont_show" : "show")
              }
            >
              <img
                src={
                  currentUser === myOwnInput.author
                    ? "typing-1.gif"
                    : "typing-2.gif"
                }
                style={{ width: "50px", color: "black" }}
                alt="typing gif"
              />
            </div>
          )}
        </ScrollToBottom>
      </div>
      <div className="chat-footer">
        <div className="messageSend">
          <LineWrappingInput
            placeholder={translation.yourMessage + "..."}
            value={currentMessage}
            onChange={(event) => {
              setCurrentMessage(event.target.value);
              LoadingIcon(event);
            }}
            onKeyPress={(event) => {
              event.key === "Enter" && sendMessage();
              setShowEmojis(false);
            }}
          />
          {showEmojis && (
            <EmojiPicker
              previewConfig={{ showPreview: false }}
              searchDisabled={true}
              categories={[
                {
                  category: "smileys_people",
                  name: translation.chooseEmoji + "...",
                },
              ]}
              allowExpandReactions={false}
              height={200}
              width="100%"
              className="my_emojis"
              onEmojiClick={handleReaction}
            />
          )}
          <i
            className="fa-regular fa-face-smile smile_icon"
            onClick={() => {
              showEmojis ? setShowEmojis(false) : setShowEmojis(true);
            }}
          ></i>
          <i
            className="fa-solid fa-paperclip attach_icon"
            onClick={chatMenuFunction}
            data-action="sendAttachment"
          ></i>
          <button className="the_submit" onClick={sendMessage}>
            <i className="fa-sharp fa-solid fa-paper-plane-top"></i>
          </button>
        </div>
        <p className="secondaryTcolor poweredText">
          Powered by&nbsp;
          <a href="http://www.smartichat.de" target="_blank" rel="noreferrer">
            <span className="mainTcolor">SmartiChat®</span>
          </a>
        </p>
      </div>
    </div>
  );
}
