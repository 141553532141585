if (typeof module === "object") {
  module.exports = {
    German: {
      support: "Support-Bot",
      language: "de",
      welcome: "Herzlich Willkommen! Wie kann ich Ihnen helfen?",
      fileSize: "Ihre Datei sollte nicht größer als 5 MB sein.",
      fileType:
        "Ihr Upload muss einem der folgenden Dateitypen entsprechen: JPG, JPEG, PNG, PDF ",
      rating: "Chatsitzung bewerten",
      zufrieden: "Wie zufrieden Sind Sie?",
      message: "Nachricht",
      file: "Datei",
      send: "Senden",
      afterSend: "senden",
      ticket: "Ticket erstellen",
      title: "Betreff",
      description: "Beschreibung",
      emailTo: "Email an",
      sendAttachment: "Anhang senden",
      noAssignedRooms: "Keine zugewiesene Chaträume",
      name: "Name",
      lang: "Sprache",
      action: "Action",
      open: "Öffnen",
      welcomeTitle: "Herzlich willkommen!",
      welcomeText1:
        "Wir sind für Sie da und freuen uns auf Ihre Fragen oder Ihr Feedback.",
      welcomeText2: "Hinweis zum Datenschutz",
      welcomeText3:
        "Mit der Nutzung unseres Chat-Systems erklären Sie sich mit der folgenden Datenschutzerklärung einverstanden:",
      welcomeText4:
        "Die im Rahmen des Chats erhobenen Daten, einschließlich der Angabe der Seite, von der aus Sie den Chat aufgerufen haben, werden nur zur Beantwortung Ihrer Fragen verwendet. Chatverläufe werden in anonymisierter Form für Trainings- und Auswertungszwecke gespeichert.",
      newSession: "Neue Sitzung",
      oldSession: "Vorherige Sitzung",
      reqConfirmCode: "Bestätigungscode anfordern",
      email: "E-Mail",
      cancel: "Abbrechen",
      giveConfirmCode: "Bestätigungscode eingeben",
      confirmCode: "Bestätigungscode",
      personalInfo: "Nur noch einige persönliche Infos",
      chooseGender: "Anrede auswählen",
      mr: "Herr",
      mrs: "Frau",
      lastName: "Nachname",
      startChat: "Chat starten",
      online: "Online",
      offline: "Offline",
      allAssignedRooms: "Alle zugewiesene Chaträume",
      leaveRoom: "Chatraum verlassen",
      stopChat: "Chat beenden",
      sendHistory: "Chatverlauf an mich",
      rateChat: "Chatsitzung bewerten",
      please: "Bitte",
      privacy: "Datenschutz",
      accept: "akzeptieren",
      accepted: "akzeptiert",
      joinedRoom: "ist dem Chatraum beigetreten",
      leftRoom: "hat den Chatraum verlassen",
      chatEnded: "Die chat-Sitzung wurde beendet",
      yourMessage: "Ihre Nachricht",
      chooseEmoji: "Emoji Auswählen",
      welcomeBack: "Willkommen zurück",
      howHelp: "Wie kann ich Ihnen heute helfen?",
      goodDay: "Guten Tag",
      zustimmung: "Ihre Zustimung unserer Datenschutzrichtlinie fehlt.",
      missingMsg: "Ihre Nachricht fehlt.",
      missingTitle: "Der Betreff Ihrer Nachricht fehlt.",
      chooseLang: "Bitte wählen Sie eine Sprache aus.",
      chooseDep: "Bitte wählen Sie eine Abteilung aus.",
      invalidEmail: "Die angegebene E-Mail ist nicht gültig.",
      missingEmail: "Ihre E-Mail Adresse fehlt.",
      missingLname: "Ihr Nachname fehlt.",
      missingGender: "Ihre Anrede fehlt.",
      missingCode: "Der Code fehlt.",
      chooseOpt: "Bitte wählen Sie eine Option aus.",
      missingDesc: "Die Beschreibung fehlt.",
      missingAttach: "Die Anlage Fehlt.",
      supportFormDesc:
        "Bitte füllen Sie das Formular aus, um eine E-Mail an support zu senden.",
      supportUnavailable:
        "Leider sind wir momentan nicht da. Bitte füllen Sie das Formular aus, um eine E-Mail an support zu senden.",
      gladlyConnect: "Gerne verbinde ich Sie mit einem Mitarbeiter.",
      chooseDepLang: "Bitte wählen Sie eine Abteilung und eine Sprache aus.",
      cancelledReq:
        "Die Anfrage wurde von Ihnen abgesagt. Wie kann ich Ihnen weiterhelfen?",
    },
    English: {
      support: "Support-Bot",
      language: "en",
      welcome: "Welcome! How can I help you?",
      fileSize: "Your file should not be larger than 5 MB.",
      fileType:
        "Your upload must be one of the following file types: JPG, JPEG, PNG, PDF",
      rating: "Rate chat session",
      zufrieden: "How satisfied are you?",
      message: "Message",
      file: "File",
      send: "Send",
      afterSend: "",
      ticket: "Create ticket",
      title: "Subject",
      description: "Description",
      emailTo: "Email",
      sendAttachment: "Send attachment",
      noAssignedRooms: "No assigned chat rooms",
      name: "Name",
      lang: "Language",
      action: "Action",
      open: "Open",
      welcomeTitle: "Welcome!",
      welcomeText1:
        "We are here for you and look forward to your questions or feedback.",
      welcomeText2: "Privacy notice",
      welcomeText3:
        "By using our chat system, you agree to the following privacy policy:",
      welcomeText4:
        "The data collected during the chat, including the page from which you accessed the chat, is used only to answer your questions. Chat histories are stored in anonymized form for training and evaluation purposes.",
      newSession: "New session",
      oldSession: "Previous session",
      reqConfirmCode: "Request confirmation code",
      email: "E-mail",
      cancel: "Cancel",
      giveConfirmCode: "Enter confirmation code",
      confirmCode: "Confirmation code",
      personalInfo: "Just a few more personal details",
      chooseGender: "Choose salutation",
      mr: "Mr.",
      mrs: "Mrs.",
      lastName: "Last name",
      startChat: "Start chat",
      online: "Online",
      offline: "Offline",
      allAssignedRooms: "All assigned chat rooms",
      leaveRoom: "Leave chat room",
      stopChat: "End chat",
      sendHistory: "Send chat history to me",
      rateChat: "Rate chat session",
      please: "Please",
      privacy: "Privacy",
      accept: "accept",
      accepted: "accepted",
      joinedRoom: "joined the chat room",
      leftRoom: "left the chat room",
      chatEnded: "The chat session has ended",
      yourMessage: "Your message",
      chooseEmoji: "Choose emoji",
      welcomeBack: "Welcome back",
      howHelp: "How can I help you today?",
      goodDay: "Good day",
      zustimmung: "Your consent to our privacy policy is missing.",
      missingMsg: "Your message is missing.",
      missingTitle: "The subject of your message is missing.",
      chooseLang: "Please select a language.",
      chooseDep: "Please select a department.",
      invalidEmail: "The email provided is not valid.",
      missingEmail: "Your email address is missing.",
      missingLname: "Your last name is missing.",
      missingGender: "Your salutation is missing.",
      missingCode: "The code is missing.",
      chooseOpt: "Please select an option.",
      missingDesc: "The description is missing.",
      missingAttach: "The attachment is missing.",
      supportFormDesc: "Please fill out the form to send an email to support.",
      supportUnavailable:
        "Unfortunately, we are currently unavailable. Please fill out the form to send an email to support.",
      gladlyConnect: "I will be happy to connect you with a representative.",
      chooseDepLang: "Please select a department and a language.",
      cancelledReq:
        "The request was canceled by you. How can I help you further?",
    },
    French: {
      support: "Support-Bot",
      language: "fr",
      welcome: "Bienvenue ! Comment puis-je vous aider ?",
      fileSize: "Votre fichier ne doit pas dépasser 5 Mo.",
      fileType:
        "Votre téléchargement doit être l'un des types de fichiers suivants : JPG, JPEG, PNG, PDF",
      rating: "Évaluer la session de chat",
      zufrieden: "Quelle est votre satisfaction ?",
      message: "Message",
      file: "Fichier",
      send: "Envoyer",
      afterSend: "",
      ticket: "Créer un ticket",
      title: "Sujet",
      description: "Description",
      emailTo: "Email à",
      sendAttachment: "Envoyer une pièce jointe",
      noAssignedRooms: "Aucune salle de chat assignée",
      name: "Nom",
      lang: "Langue",
      action: "Action",
      open: "Ouvrir",
      welcomeTitle: "Bienvenue !",
      welcomeText1:
        "Nous sommes là pour vous et nous attendons vos questions ou vos commentaires.",
      welcomeText2: "Avis de confidentialité",
      welcomeText3:
        "En utilisant notre système de chat, vous acceptez la politique de confidentialité suivante :",
      welcomeText4:
        "Les données collectées pendant le chat, y compris la page à partir de laquelle vous avez accédé au chat, ne sont utilisées que pour répondre à vos questions. Les historiques de chat sont stockés sous forme anonymisée à des fins de formation et d'évaluation.",
      newSession: "Nouvelle session",
      oldSession: "Session précédente",
      reqConfirmCode: "Demander un code de confirmation",
      email: "E-mail",
      cancel: "Annuler",
      giveConfirmCode: "Entrer le code de confirmation",
      confirmCode: "Code de confirmation",
      personalInfo: "Encore quelques informations personnelles",
      chooseGender: "Choisissez une civilité",
      mr: "Monsieur",
      mrs: "Madame",
      lastName: "Nom de famille",
      startChat: "Démarrer le chat",
      online: "En ligne",
      offline: "Hors ligne",
      allAssignedRooms: "Toutes les salles de chat assignées",
      leaveRoom: "Quitter la salle de chat",
      stopChat: "Terminer le chat",
      sendHistory: "Envoyer l'historique du chat",
      rateChat: "Évaluer la session de chat",
      please: "S'il vous plaît",
      privacy: "Confidentialité",
      accept: "accepter",
      accepted: "accepté",
      joinedRoom: "a rejoint la salle de chat",
      leftRoom: "a quitté la salle de chat",
      chatEnded: "La session de chat est terminée",
      yourMessage: "Votre message",
      chooseEmoji: "Choisir un emoji",
      welcomeBack: "Bon retour",
      howHelp: "Comment puis-je vous aider aujourd'hui ?",
      goodDay: "Bonjour",
      zustimmung:
        "Votre consentement à notre politique de confidentialité manque.",
      missingMsg: "Votre message est manquant.",
      missingTitle: "Le sujet de votre message est manquant.",
      chooseLang: "Veuillez sélectionner une langue.",
      chooseDep: "Veuillez sélectionner un département.",
      invalidEmail: "L'email fourni n'est pas valide.",
      missingEmail: "Votre adresse e-mail est manquante.",
      missingLname: "Votre nom de famille est manquant.",
      missingGender: "Votre civilité est manquante.",
      missingCode: "Le code est manquant.",
      chooseOpt: "Veuillez sélectionner une option.",
      missingDesc: "La description est manquante.",
      missingAttach: "La pièce jointe est manquante.",
      supportFormDesc:
        "Veuillez remplir le formulaire pour envoyer un e-mail au support.",
      supportUnavailable:
        "Malheureusement, nous ne sommes pas disponibles pour le moment. Veuillez remplir le formulaire pour envoyer un e-mail au support.",
      gladlyConnect: "Je serai heureux de vous connecter avec un représentant.",
      chooseDepLang: "Veuillez sélectionner un département et une langue.",
      cancelledReq:
        "La demande a été annulée par vous. Comment puis-je vous aider davantage ?",
    },
  };
}
