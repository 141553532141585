if (typeof module === "object") {
  module.exports = {
    env: {
      URL_HOST: "https://ejconchat.smartichat.de",
      URL_LARAVEL: "https://ejcon.smartichat.de",
      URL_FRONTEND: "https://ejconchat.smartichat.de",
      NODE_ENV: "production",
      SSL_CERT: "https://ejconchat.smartichat.de/certificates/fullchain.pem",
      SSL_KEY: "https://ejconchat.smartichat.de/certificates/privkey.pem",
    },
  };
}
