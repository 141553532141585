import React, { useEffect, useState } from "react";
import "./App.css";
import io from "socket.io-client";
import "bootstrap/dist/css/bootstrap.css";
import Chat from "./Chat";
import axios from "axios";
import envData from "./next.conf";
import translations from "./translations";

const env = envData.env;

const socket = io(env.URL_HOST, {
  path: "/socket",
  reconnection: true,
  transports: ["websocket", "polling"],
  reconnectionAttempts: 5,
});

socket.on("connect", () => {
  socket.emit("connected");
});

function App() {
  const [username, setUsername] = useState("Support-Bot");
  const [room, setRoom] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [noChatbot, setNoChatbot] = useState(false);
  const [feedData, setFeedData] = useState("");
  const [currentUser, setCurrentUser] = useState("Sie");
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [empImage, setEmpImage] = useState("chatbackground.jpg");
  const [joinedRoom, setJoinedRoom] = useState(false);
  const [leftRoom, setLeftRoom] = useState(false);
  const [endChat, setEndChat] = useState(false);
  const [startChat, setStartChat] = useState(false);
  const [startOldChat, setStartOldChat] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [chatHistory, setChatHistory] = useState(false);
  const [sessionActive, setSessionActive] = useState(false);
  const [chatDepartments, setChatDepartments] = useState({});
  const [showWelcome, setShowWelcome] = useState(false);
  const [newMessage, setNewMessage] = useState(false);
  const [chatLang, setChatLang] = useState("German");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [onlineStatus, setOnlineStatus] = useState("1");
  const [buttons, setButtons] = useState([]);

  const browserLang = window.location.href.split("=");

  if (browserLang[1] && chatLang !== browserLang[1])
    setChatLang(browserLang[1]);

  let translation;
  if (chatLang === "English") translation = translations.English;
  else if (chatLang === "French") translation = translations.French;
  else translation = translations.German;

  /* function for generating a rand room id */
  function makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  /* function for opening chat window */
  function openChat() {
    setShowChat(true);
    setNewMessage(false);
    window.top.postMessage("openChat", "*");
  }

  function fnShowWelcome() {
    setShowWelcome(false);
    localStorage.setItem("showWelcome", "false");
    window.top.postMessage(
      "closeChat|" + localStorage.getItem("showWelcome"),
      "*"
    );
  }

  /* function for joining room */
  function joinRoom(roomData = null) {
    const sub_button_1 = document.querySelector(".the_submit");
    if (room === "" && !startChat) {
      if (!localStorage.getItem("showWelcome"))
        localStorage.setItem("showWelcome", "false");

      setEmpImage("chatbackground.jpg");
      const sub_button_1_room = sub_button_1.dataset.room;
      let date_id = "";
      if (sub_button_1_room) {
        date_id = sub_button_1_room;
        setIsAdmin(true);
        localStorage.setItem("isAdmin", "true");
      } else if (roomData.id) {
        date_id = roomData.id;
      } else {
        date_id = Date.now() + makeid(10);
      }

      setRoom(date_id);

      if (roomData && roomData.email) setCurrentUserEmail(roomData.email);

      setUsername(
        sub_button_1.dataset.kundenusername
          ? sub_button_1.dataset.kundenusername
          : username
      );

      if (roomData !== null && roomData.anrede) {
        setCurrentUser(roomData.anrede + " " + roomData.nachname);
      } else if (sub_button_1.dataset.username) {
        setCurrentUser(sub_button_1.dataset.username);
      }

      socket.emit("emp_image", {
        room: date_id,
        image: sub_button_1.dataset.img
          ? sub_button_1.dataset.img
          : "chatbackground.jpg",
        username: sub_button_1.dataset.username
          ? sub_button_1.dataset.username
          : username,
        sys_name: sub_button_1.dataset.kundenusername
          ? sub_button_1.dataset.kundenusername
          : username,
        action: sub_button_1.dataset.action ? sub_button_1.dataset.action : "",
      });

      /* control for AI chatbot  */
      setNoChatbot(
        sub_button_1.dataset.bot && sub_button_1.dataset.bot === "true"
          ? true
          : false
      );
      /* room data information */
      let data_room = "";
      let data_room_storage = "";
      if (roomData !== null && roomData.anrede) {
        data_room = {
          id: date_id,
          bot:
            sub_button_1.dataset.bot && sub_button_1.dataset.bot === "true"
              ? true
              : false,
          anrede: roomData.anrede,
          nachname: roomData.nachname,
          email: roomData.email,
          username: username,
          alteChat: roomData.id ? true : startOldChat,
          session: sessionActive ? true : false,
          translation: translation,
        };

        data_room_storage = {
          id: date_id,
          bot:
            sub_button_1.dataset.bot && sub_button_1.dataset.bot === "true"
              ? true
              : false,
          anrede: roomData.anrede,
          nachname: roomData.nachname,
          email: roomData.email,
          username: username,
          alteChat: true,
          session: true,
          empImage: empImage,
        };

        localStorage.setItem("room", JSON.stringify(data_room_storage));
        localStorage.setItem("online", "true");

        if (sub_button_1_room) localStorage.setItem("room_status", "2");
        else localStorage.setItem("room_status", "0");
      } else {
        data_room = {
          id: date_id,
          bot:
            sub_button_1.dataset.bot && sub_button_1.dataset.bot === "true"
              ? true
              : false,
          alteChat: startOldChat,
          session: sessionActive ? true : false,
          translation: translation,
        };
      }
      socket.emit("join_room", data_room);
      setStartChat(true);

      /* set variable to chat menu when chat history button is clicked */
      if (
        sub_button_1.dataset.action &&
        sub_button_1.dataset.action === "chatHistory"
      ) {
        setChatHistory(true);
        setEndChat(true);
      } else {
        setChatHistory(false);
        setEndChat(false);
      }

      /* set variable to chat menu when old room or new room button is clicked */
      if (
        sub_button_1.dataset.action &&
        (sub_button_1.dataset.action === "newRoom" ||
          sub_button_1.dataset.action === "oldRoom")
      ) {
        setChatHistory(false);
        setEndChat(false);
        setLeftRoom(false);
      }
    } else {
      socket.emit("join_room", room);
    }

    setShowChat(true);
    setNewMessage(false);
    window.top.postMessage("openChat", "*");
  }

  useEffect(() => {
    /* show welcome message after timer */
    setTimeout(() => {
      setShowWelcome(true);
    }, 2000);

    /* fetch all needed data */

    async function fetchAll() {
      axios
        .get(
          "" + env.URL_LARAVEL + "/api/v1/fetchAll/" + translation.language + ""
        )
        .then((response) => {
          const chatData =
            response.data.company.data_1.replace("[LANGUAGE]", chatLang) +
            response.data.company.data_2;
          setFeedData(chatData);
          setChatDepartments(response.data.dep_lang);
          setStartTime(response.data.company.start_time);
          setEndTime(response.data.company.end_time);
          setOnlineStatus(response.data.company.online);
          setButtons(response.data.buttons);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    /* check localstorage for current chat */
    const localStorageFunction = async () => {
      if (
        document.readyState === "complete" ||
        document.readyState === "interactive"
      ) {
        window.top.postMessage(
          "closeChat|" + localStorage.getItem("showWelcome"),
          "*"
        );

        if (
          localStorage.getItem("room") &&
          localStorage.getItem("online") &&
          localStorage.getItem("room_status")
        ) {
          const myRoom = JSON.parse(localStorage.getItem("room"));
          const roomStatus = localStorage.getItem("room_status");
          if (myRoom && roomStatus && !localStorage.getItem("isAdmin")) {
            try {
              setTimeout(() => {
                axios
                  .get(
                    "" +
                      env.URL_LARAVEL +
                      "/api/v1/roomStatus/" +
                      myRoom.id +
                      ""
                  )
                  .then((response) => {
                    if (response.data.toString().includes("Error")) {
                      console.log(response.data);
                    } else {
                      if (response.data.toString() === roomStatus.toString()) {
                        setTimeout(async () => {
                          await setSessionActive(true);
                          await socket.emit("join_room", myRoom);
                          setCurrentUser(myRoom.anrede + " " + myRoom.nachname);
                          setRoom(myRoom.id);
                          setEmpImage(myRoom.empImage);
                          setNoChatbot(myRoom.bot);
                          setUsername(myRoom.username);
                          //console.log(JSON.stringify(myRoom));
                          setStartChat(true);
                        }, 200);
                        if (localStorage.getItem("online") === "true")
                          setLeftRoom(false);
                        else setLeftRoom(true);
                      } else {
                        window.top.postMessage(
                          "endChat|" +
                            myRoom.id +
                            "|" +
                            localStorage.getItem("showWelcome"),
                          "*"
                        );
                        setShowWelcome(true);
                        localStorage.clear();
                      }
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }, 1000);
            } catch (error) {
              console.error(error);
            }
          }
        }
      }
    };

    /* get employee information from dashboard */

    const windowMessageFunction = (e) => {
      window.removeEventListener("message", windowMessageFunction);
      if (e.data.toString().includes("room")) {
        const sub_button = document.querySelector(".the_submit");
        const emp_array = e.data.toString().split("|");
        sub_button.dataset.room = emp_array[0].split(",")[1];
        sub_button.dataset.img = `${emp_array[1].split(",")[1]}`;
        sub_button.dataset.username = emp_array[2].split(",")[1];
        sub_button.dataset.kundenusername = emp_array[3].split(",")[1];
        sub_button.dataset.action = emp_array[4].split(",")[1];
        sub_button.dataset.bot = "true";
        setTimeout(() => {
          joinRoom();
        }, 200);
      }

      if (e.data.toString().includes("onlineStatus"))
        socket.emit("onlineStatus", e.data.split("|")[1]);

      setTimeout(() => {
        window.addEventListener("message", windowMessageFunction);
      }, 1000);
    };

    window.addEventListener("message", windowMessageFunction);

    socket.on("receive_room", (data) => {
      setRoom(data);
    });

    socket.on("new_notification", (data) => {
      localStorage.setItem("room_status", "1");
      window.top.postMessage(
        "new_notification|" + data.name + "|" + data.room,
        "*"
      );
    });

    socket.on("renew_chat_status", (data) => {
      window.top.postMessage("renew_chat_status|" + data.id, "*");
    });

    socket.on("join_new_room", (data) => {
      joinRoom(data);
    });
    socket.on("onlineStatus", (data) => {
      setOnlineStatus(data);
      window.top.postMessage("onlineStatus|" + data, "*");
    });

    localStorageFunction();
    fetchAll();

    return () => {
      socket.removeListener("join_new_room");
      socket.removeListener("renew_chat_status");
      socket.removeListener("new_notification");
      socket.removeListener("receive_room");
      socket.removeListener("onlineStatus");
    };
  }, []);

  return (
    <div className="App">
      <div
        className={
          "chatIconMainContainer" +
          (showChat === false ? " slideUp" : " slideDown")
        }
      >
        {showWelcome && !localStorage.getItem("showWelcome") && (
          <div className="welcomeContainer text-left">
            <i
              className="fa-sharp fa-solid fa-circle-xmark md_text mainTcolor closeWelcome"
              onClick={fnShowWelcome}
            ></i>
            <p className="xs_text bold_weight mb-2 mainTcolor">
              {translation.support}
            </p>
            <p className="sm_text tColor1 welcome_text mb-0">
              {translation.welcome}
            </p>
          </div>
        )}
        <div className="w-100 text-right">
          {newMessage && (
            <i className="fa-sharp fa-solid fa-circle-envelope mainTcolor md_text new_message"></i>
          )}
          <div
            className="ChatIconContainer ChatIconContainer_2"
            onClick={openChat}
          ></div>
        </div>
      </div>
      <Chat
        axios={axios}
        socket={socket}
        username={username}
        setUsername={setUsername}
        room={room}
        setRoom={setRoom}
        showChat={showChat}
        setShowChat={setShowChat}
        noChatbot={noChatbot}
        setNoChatbot={setNoChatbot}
        setEmpImage={setEmpImage}
        empImage={empImage}
        feedData={feedData}
        currentUser={currentUser}
        currentUserEmail={currentUserEmail}
        joinedRoom={joinedRoom}
        setJoinedRoom={setJoinedRoom}
        leftRoom={leftRoom}
        setLeftRoom={setLeftRoom}
        endChat={endChat}
        setEndChat={setEndChat}
        startChat={startChat}
        setStartChat={setStartChat}
        startOldChat={startOldChat}
        setStartOldChat={setStartOldChat}
        isAdmin={isAdmin}
        chatHistory={chatHistory}
        chatDepartments={chatDepartments}
        env={env}
        setNewMessage={setNewMessage}
        startTime={startTime}
        endTime={endTime}
        onlineStatus={onlineStatus}
        buttons={buttons}
        translation={translation}
      />
    </div>
  );
}

export default App;
